<template>
    <el-table :default-sort="preSelectedSort" :data="invoicesData" @sort-change="sortChange" v-loading="$waiting.is('loading')" @selection-change="handleSelectionChange" size="mini">
        <el-table-column prop="paymentStatus" label="Status" align="center" sortable="custom" width="90" />
        <el-table-column prop="typeName" label="Typ" align="center" sortable="custom" width="110" />
        <el-table-column prop="factoring" label="Factoring" sortable="custom" width="110">
            <template v-if="scope.row.factoring" slot-scope="scope">Factoring</template>
            <!-- <template v-else slot-scope="scope"></template> -->
        </el-table-column>

        <el-table-column prop="clientName" label="Klient" sortable="custom" show-overflow-tooltip>
            <template slot-scope="scope">FE {{ scope.row.clientNumber }}, {{ scope.row.clientName }}</template>
        </el-table-column>
        <el-table-column prop="customerName" label="Kund" sortable="custom" show-overflow-tooltip />
        <el-table-column prop="invoiceNumber" label="Fak nr" sortable="custom" width="100" />
        <el-table-column prop="ocrNumber" label="OCR" sortable="custom" width="110" />
        <el-table-column prop="paymentAmount" label="Kvar att betala" align="right" sortable="custom" width="150">
            <template slot-scope="scope">{{ scope.row.paymentRest | swedishNumberFormat }} {{ scope.row.currency }}</template>
        </el-table-column>
        <el-table-column prop="grossAmount" align="right" label="Bruttobelopp" sortable="custom" width="140">
            <template slot-scope="scope">{{ scope.row.grossAmount | swedishNumberFormat }} {{ scope.row.currency }}</template>
        </el-table-column>
        <el-table-column prop="date" label="Datum" sortable="custom" width="100">
            <template slot-scope="scope">
                <p>
                    {{ formatDate(scope.row.date) }}
                </p>
            </template>
        </el-table-column>
        <el-table-column prop="dueDate" label="Förfallodatum" sortable="custom" width="140">
            <template slot-scope="scope">
                <p>
                    {{ formatDate(scope.row.dueDate) }}
                </p>
            </template>
        </el-table-column>
        <el-table-column prop="dueDate" width="100" align="right">
            <template slot-scope="scope">
                <el-popover
                    v-if="scope.row.comment != null"
                    popper-class="bg-company-blue text-white"
                    title="Notera"
                    trigger="hover"
                    placement="left"
                    :content="scope.row.comment"
                    :visible-arrow="false"
                    width="500"
                >
                    <el-button type="primary" class="p-1 mr-2" slot="reference">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </el-button>
                </el-popover>
                <el-tooltip class="item" effect="dark" content="Details" placement="left">
                    <router-link :to="'invoices/' + scope.row.id">
                        <el-button type="primary" class="p-1">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0zM10 7v3m0 0v3m0-3h3m-3 0H7" />
                            </svg>
                        </el-button>
                    </router-link>
                </el-tooltip>
            </template>
        </el-table-column>
    </el-table>
</template>
<script>
import queryString from "query-string";
import moment from "moment";
export default {
    props: {
        invoicesData: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            sortBy: null,
            sortByType: null,
        };
    },

    created() {
        this.assignInitValues();
    },

    computed: {
        preSelectedSort() {
            if (!this.sortBy) return {};
            return {
                prop: this.sortBy,
                order: this.sortByType,
            };
        },
    },

    methods: {
        assignInitValues() {
            const { sortBy, sortByType } = queryString.parse(location.search);
            this.sortBy = sortBy;
            this.sortByType = sortByType;
        },

        formatDate(date) {
            return moment(date).format("YYYY-MM-DD");
        },

        sortChange(event) {
            const query = queryString.parse(window.location.search, { sort: false });
            query.sortBy = event.order ? event.prop : undefined;
            query.sortByType = event.order || undefined;
            this.$router.replace({ query }).catch(() => {});
            this.$emit("sortTypeChanged");
        },

        handleSelectionChange() {},
    },
};
</script>
<style>
.el-popover__title {
    color: #fff;
}
</style>
