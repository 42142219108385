<template>
    <el-table
        @row-click="handleRowClick"
        :default-sort="preSelectedSort"
        :data="receiptsData"
        @sort-change="sortChange"
        v-loading="$waiting.is('loading')"
        @selection-change="handleSelectionChange"
        size="mini"
    >
        <el-table-column prop="status" label="Status" align="center" sortable="custom" width="110">
            <template slot-scope="scope">{{ translate(scope.row.status) }}</template>
        </el-table-column>
        <el-table-column prop="type" label="Typ" align="center" sortable="custom" width="160">
            <template slot-scope="scope">{{ translate(scope.row.type) }}</template>
        </el-table-column>
        <el-table-column prop="clientName" label="Klient" sortable="custom" show-overflow-tooltip>
            <template slot-scope="scope">FE {{ scope.row.clientNumber }}, {{ scope.row.clientName }}</template>
        </el-table-column>
        <el-table-column prop="purpose" label="Syfte" sortable="custom" show-overflow-tooltip />
        <el-table-column prop="amount" align="right" label="Belopp" sortable="custom" width="140">
            <template slot-scope="scope">{{ scope.row.amount | swedishNumberFormat }}</template>
        </el-table-column>
        <el-table-column prop="date" label="Datum" sortable="custom" width="100">
            <template slot-scope="scope">
                <p>
                    {{ formatDate(scope.row.date) }}
                </p>
            </template>
        </el-table-column>
        <el-table-column prop="isPayed" label="Utbetald" sortable="custom" width="120">
            <template slot-scope="scope">
                <p>
                    {{ formatDate(scope.row.isPayed) }}
                </p>
            </template>
        </el-table-column>
        <el-table-column prop="registrationDate" label="Registrerings Datum" sortable="custom" width="200">
            <template slot-scope="scope">
                <p>
                    {{ formatDate(scope.row.registrationDate) }}
                </p>
            </template>
        </el-table-column>
        <el-table-column prop="receiptNumber" label="Kvitto nummer" sortable="custom" width="160" />
        <el-table-column prop="underlayIsMissing" label="" width="40">
            <template slot-scope="scope"><i v-if="scope.row.hasAttachment" class="fa-light fa-paperclip-vertical fa-lg"/></template>
        </el-table-column>
        <el-table-column prop="id2" width="50">
            <template slot-scope="scope">
                <i class="fas fa-lock" v-if="lockedIds.find(lock => lock.id == scope.row.id)" :title="lockedIds.find(lock => lock.id == scope.row.id).name" />
            </template>
        </el-table-column>
    </el-table>
</template>
<script>
import queryString from "query-string";
import moment from "moment";
export default {
    props: {
        receiptsData: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            sortBy: null,
            sortByType: null,
            lockedIds: [],
            connection: null,
        };
    },
    mounted() {
        const url = process.env.VUE_APP_SIGR_URL + "lockinghub";
        const signalR = require("@microsoft/signalr");
        var connection = new signalR.HubConnectionBuilder()
            .configureLogging(signalR.LogLevel.Error)
            .withUrl(url)
            .build();
        connection.on("lockUpdate", lockedIds => {
            console.log("🚀 - file: ReceiptsTable.vue:102 - mounted - lockedIds:", lockedIds);
            this.lockedIds = lockedIds;
        });
        connection.on("remove", id => {
            console.log("🚀 - file: ReceiptsTable.vue:110 - mounted - remove id:", id);
            this.$emit("remove", id);
        });
        connection
            .start()
            .then(function() {
                connection.invoke("onConnected", 5);
            })
            .catch(function(err) {
                return console.error(err.toString());
            });
        this.connection = connection;
    },

    beforeRouteLeave: async function(to, from, next) {
        await this.connection.stop();
        next();
    },

    created() {
        this.assignInitValues();
    },

    computed: {
        preSelectedSort() {
            if (!this.sortBy) return {};
            return {
                prop: this.sortBy,
                order: this.sortByType,
            };
        },
    },

    methods: {
        async handleRowClick(row) {
            let confirmed = true;
            let locked = this.lockedIds.find(lock => lock.id == row.id);
            if (locked) {
                confirmed = await this.$dialog
                    .title("Låst kvitto")
                    .html()
                    .confirm(`Vill du arbeta med kvittot även fast ${locked.name} har låst det?`);
            }
            if (confirmed) {
                // Build the query parameters dynamically
                const query = {
                    ...(this.$route.query.query ? { query: this.$route.query.query } : {}),
                    ...(this.$route.query.sortBy ? { sortBy: this.$route.query.sortBy } : {}),
                    ...(this.$route.query.sortByType ? { sortByType: this.$route.query.sortByType } : {}),
                    ...(this.$route.query.status ? { status: this.$route.query.status } : {}),
                    ...(this.$route.query.currency ? { currency: this.$route.query.currency } : {}),
                    ...(this.$route.query.dateFrom ? { dateFrom: this.$route.query.dateFrom } : {}),
                    ...(this.$route.query.dateTo ? { dateTo: this.$route.query.dateTo } : {}),
                    ...(this.$route.query.clientId ? { clientId: this.$route.query.clientId } : {}),
                    ...(this.$route.query.test ? { test: this.$route.query.test } : {}),
                    ...(this.$route.query.category ? { category: this.$route.query.category } : {}),
                    ...(this.$route.query.pageNumber ? { pageNumber: this.$route.query.pageNumber } : {}),
                    ...(this.$route.query.pageSize ? { pageSize: this.$route.query.pageSize } : {}),
                };

                // Navigate to the target route
                this.$router.push({
                    path: `/receipts/${row.id}`,
                    query,
                });
            }
        },

        translate(string) {
            const translation = {
                Registered: "Registrerad",
                Approved: "Godkänd",
                Denied: "Nekad",
                Supplement: "Kompletera",
                Supplemented: "Kompleterad",
                Deleted: "Raderad",
                Proposal: "Förslag",
                PrivateExpenses: "Privat utlägg",
                CompanyAccount: "Företagskort/-konto",
                CompanyCreditCard: "Företagskreditkort",
                Draft: "Utkast",
            };
            return translation[string];
        },

        assignInitValues() {
            const { sortBy, sortByType } = queryString.parse(location.search);
            this.sortBy = sortBy;
            this.sortByType = sortByType;
        },

        formatDate(date) {
            if (date == null) return "";
            return moment(date).format("YYYY-MM-DD");
        },

        sortChange(event) {
            const query = queryString.parse(window.location.search, { sort: false });
            query.sortBy = event.order ? event.prop : undefined;
            query.sortByType = event.order || undefined;
            this.$router.replace({ query }).catch(() => {});
            this.$emit("sortTypeChanged");
        },

        handleSelectionChange() {},
    },
};
</script>
<style>
.el-popover__title {
    color: #fff;
}
</style>
